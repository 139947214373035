import { Component, Vue, Watch } from 'vue-property-decorator'

//config
import { setConfigSidebar, getConfigSidebar } from '@/config'

//components
import Navbar from '@/components/navbar/Navbar.vue'
import ListPanel from '@/components/listPanel/ListPanel.vue'
import ListMenuOption from '@/components/listMenuOption/ListMenuOption.vue'
import ListGroups from '@/components/listGroups/ListGroups.vue'
import PrivateContent from '@/components/privateContent/PrivateContent.vue'
import HistoryProcess from '@/components/historyProcess/HistoryProcess.vue'
import Footer  from '@/components/footer/Footer.vue';

//molds
import { ListMenuOptionModel } from '@/molds/ListMenuOptionModel';
import { ProcessModel } from '@/molds/ProcessModel';
import { ListPanelModel } from '@/molds/ListPanelModel';
import { SidebarOptions } from '@/components/wrappers/molds/SidebarItem'
import { IconListItem, IconListOptions } from '@/components/wrappers/molds/IconListItem'
import { Process } from '@/store/modules/process/process'

//services
import { ProcessService } from '@/services/processService'

//wrappers
import { 
	Box, 
	WTabs,
	WColumns,
	WColumn,
  WButton,
  WHr,
	WSubtitle,
	Loader,
	Sidebar,
	IconList,
	Container,
	Autocomplete,
	SizedBox,
	Arrow
} from '@/components/wrappers';

@Component({
	components:{
		SizedBox,
		WSubtitle,
		Navbar,
		Container,
		Box,
		ListPanel,
		WTabs,
		WColumns,
    WColumn,
    ListMenuOption,
    WButton,
    WHr,
    ListGroups,
    PrivateContent,
    HistoryProcess,
		Loader,
		Sidebar, 
		IconList,
		Autocomplete,
		Footer,
		Arrow
	}
})
export default class Home extends Vue {

	selectItem = 1
	sidebar = false
	process: any = []
  totalProcess = 0
  labelItemSelect = ''
  dropDownMyGroups: any = [];
	processService = new ProcessService(this.$store)

  get isLoadingHistory() {
    return this.$store.getters['process/loader']
  }

  get isLoaderProcessList() {
    return this.$store.getters['process/loaderList']
  }

  get processList(){
    return this.$store.getters['process/wolfApiProcess']
  }

  set processList(newValue: any){
    this.$store.commit('process/setGroupProces', {list:newValue});
  }

  get selectProcess(){
    return this.$store.getters['process/process']
  }

  set selectProcess(newValue: any){
    this.$store.commit('process/setProcess', newValue);
  }

	get labelCountProcess(): string {
		if(this.totalProcess > 1){
			return `${this.totalProcess} Processos`
		} return `${this.totalProcess} Processo`
	}

	async mounted(){
    this.processList = [];
		this.initSidenav()
		// this.getProcess("?_limit=300")
		this.getMyGroups()
		this.verifyParamQuery()
    
		document.title = 'Home'
	}

	verifyParamQuery() {
		//verifica se para metro query history é numero
		if(!isNaN(parseInt(`${this.$route.query.history}`))){
			this.dispatchSelectProcess(this.selectProcess, `${this.$route.query.history}`)
		}
	}

	async getProcess(query: string){
    this.$store.commit('process/setLoader', true);
		const process = await this.processService.dispatchSearchProcess(query)
    
		if(process){
			this.process = process
		}
    this.$store.commit('process/setLoader', false);
	}

  async getMyGroups(){
		const mygroups = await this.processService.dispatchGetMyGroups()
    if(mygroups){
			this.dropDownMyGroups = mygroups.map( (group: any) => {
        return {
          idGroup: group.idGroup,
          label: group.name
        }
      })
		}
    if( this.dropDownMyGroups.length > 0 ){
      this.sidebarItemClick(this.dropDownMyGroups[0])
    }
	}

  async getGroupProcess(idGroup: string){
    this.totalProcess = 0
    this.$store.commit('process/setGroupProces', {list:[]});
    const process = await this.processService.dispatchGetGroupProcess(idGroup)
    this.totalProcess = this.processService.getTotalProcess();
	}

  getFirstGroup(){
    if( this.dropDownMyGroups.length > 0 ){
      return this.dropDownMyGroups[0].idGroup;
    }
    return 0;
  }

  get listMenuOption(): ListMenuOptionModel | null {
		if(this.processList == undefined && this.isLoaderProcessList){
			return null
		}
		return {
			columns: [
				{title: 'Núm/Ano', size: 18, field: 'numYear'},
				{title: 'Detalhes', size: 25, field: 'details'},
				{title: 'Data/hora', size: 20, field: 'dateTimeOpened'},
				{title: 'Status', size: 20, field: 'status'}
			],
			data: this.processList?.list || []
		}
  }

	async dispatchSelectProcess(process: Process, idProcess?: string): Promise<any> {
		// this.isLoadingHistory = true
    let id
		if(idProcess){
			id = idProcess
		}else{
			id = process.idProcess
      if( this.$route.query.history != id){
        this.$router.push({ path: '/home', query: { history: id }})
      }
		}

		const result = await this.processService.dispatchProcess(id)
		if(result){
			this.selectProcess = result
		}else{
			this.selectProcess = {}
		}
		// this.isLoadingHistory = false
	}

	initSidenav(): void {
		if(getConfigSidebar() == 'true'){
			this.sidebar = true
		}
	}

	get SelectItem(){
		return this.selectItem
	}

	set SelectItem(element) {
		this.selectItem = element;
	}

	setIndex(index: number): void {
		//ignorar "novo grupo" e "meus grupos"
		if(index != 0){
			this.SelectItem = index
		}
	}

  openSidebar(): void {
    this.sidebar = !this.sidebar
    setConfigSidebar(this.sidebar)
  }

  sidebarItemClick(item: any): void {
    this.labelItemSelect = item.label;
    if (item.idGroup != undefined) {
      this.getGroupProcess(item.idGroup)
    }
    else{
      this.processList = []
      this.totalProcess = 0
    }
  }

	iconListItemSelected(item: IconListItem): void {
		if(item.id != null && item.id == 7){
			this.sidebar = true
			setConfigSidebar(this.sidebar)
		}
	}

	spaceSidenav(): string {
		if(this.sidebar) return "space-sidenav"
		else return ""
	}

	get isSidebar(){
		return this.sidebar
	}

	iconList: IconListOptions = {
		isColumn: true,
		class: 'theme-border-rounded',
		classIcons:'icons-list',
		IconListItens: [
			// {
			// 	icon: 'fas fa-plus',
			// 	title: 'Novo Processo',
			// 	iconClass:'item-white'
			// },
			// {
			// 	icon: 'mdi mdi-inbox',
			// 	title: 'Todos os processos'
			// },
			// {
			// 	icon: 'mdi mdi-send',
			// 	title:'Processos enviados'
			// },
			// {
			// 	icon: 'mdi mdi-file-undo',
			// 	title:'Processos retornados'
			// },
			// {
			// 	icon: 'mdi mdi-file-cabinet',
			// 	title:'Processos arquivados'
			// },
			{
				id: 7,
				title:'Meus grupos', 
				icon:"mdi mdi-account-multiple",
				iconClass: 'item-white' 
			},
			// {
			// 	icon: 'mdi mdi-star',
			// 	title:'Processos importantes'
			// },
		]
	}

	get sidebarOptions(): SidebarOptions {
		return {
			class: "sidebar theme-border-rounded",
			items: [
				// {
				// 	label: "Novo Processo",
				// 	icon:"fas fa-plus",
				// 	title: 'Novo Processo'
				// },
				// {
				// 	label: 'Caixa de entrada',
				// 	icon: 'mdi mdi-inbox',
				// 	title: 'Todos os processos'
				// },
				// {
				// 	icon: 'mdi mdi-send',
				// 	label: 'Enviados',
				// 	title:'Processos enviados'
				// },
				// {
				// 	icon: 'mdi mdi-file-undo',
				// 	label: 'Retornados',
				// 	title:'Processos retornados'
				// },
				// {
				// 	icon: 'mdi mdi-file-cabinet',
				// 	label: 'Arquivados',
				// 	title:'Processos arquivados'
				// },
				{
					label: "Meus Grupos", 
					title:'Grupos que participo', 
					icon:"mdi mdi-account-multiple",
					active: true,
					dropDown: this.dropDownMyGroups
				},
        // {
				// 	icon: 'mdi mdi-star',
				// 	label: 'Importantes',
				// 	title:'Importantes'
				// },
			]
		}
	}
  

	@Watch('$route.query.history')
	queryHistory(){
		this.verifyParamQuery()
	}

	get isShowHistory(): boolean {
    if(this.$route.query.history != undefined){
      return true;
    }
    return false;
  }

	// ############### OLD ################

  clickRowGroup(row: any): void {
    this.$router.push({ path: '/my-group'})
  }

  clickRowListPanel1(row: any): void {
    console.log(row);
  }

  clickRowListPanel2(row: any): void {
    console.log(row);
  }

  listPanel1: ListPanelModel = {
    field: 'name'
  }

  listPanel2: ListPanelModel = {
    field: 'name'
  }

  listPanel1Data = [
    { name: 'Todos Processos' },
    { name: 'Processos em Acompanhamento' },
    { name: 'Meus Processos' }
  ];

  listPanel2Data = [
    { name: 'Grupo 1' },
    { name: 'Grupo 2' }
  ];

  processo: ProcessModel = {
    numAno: '0001/2021',
    dataHora: '00/00/0000 00:00:00',
    status: 1,
    grupoLocalNome: 'grupoLocalNome',
    userOrigemNome: 'grupoDestinoNome',
    grupoOrigemNome: 'grupoOrigemNome',
    grupoDestinoNome: 'grupoDestinoNome',
    assunto: 'assunto',
    detalhes: 'detalhes',
    history: [
      {
        operacao: 'Encaminhado',
        dataHora: '00/00/0000 00:00:00',
        local: 'Protocolo',
        para: 'Setor xxx',
        usuario: 'Usuario xxx',
        comentario: 'operação realizada por tal coisa',
        anexos: [
          {
            nome: 'file.pdf',
            url: '#'
          }
        ]
      },
      {
        operacao: 'Encaminhado',
        dataHora: '00/00/0000 00:00:00',
        local: 'Protocolo',
        para: 'Setor xxx',
        usuario: 'Usuario xxx',
        comentario: 'operação realizada por tal coisa',
        anexos: [
          {
            nome: 'file.pdf',
            url: '#'
          }
        ]
      },
      {
        operacao: 'Encaminhado',
        dataHora: '00/00/0000 00:00:00',
        local: 'Protocolo',
        para: 'Setor xxx',
        usuario: 'Usuario xxx',
        comentario: 'operação realizada por tal coisa',
        anexos: [
          {
            nome: 'file.pdf',
            url: '#'
          }
        ]
      },
      {
        operacao: 'Encaminhado',
        dataHora: '00/00/0000 00:00:00',
        local: 'Protocolo',
        para: 'Setor xxx',
        usuario: 'Usuario xxx',
        comentario: 'operação realizada por tal coisa',
        anexos: [
          {
            nome: 'file.pdf',
            url: '#'
          }
        ]
      },
      {
        operacao: 'Encaminhado',
        dataHora: '00/00/0000 00:00:00',
        local: 'Protocolo',
        para: 'Setor xxx',
        usuario: 'Usuario xxx',
        comentario: 'operação realizada por tal coisa',
        anexos: [
          {
            nome: 'file.pdf',
            url: '#'
          }
        ]
      },
      {
        operacao: 'Encaminhado',
        dataHora: '00/00/0000 00:00:00',
        local: 'Protocolo',
        para: 'Setor xxx',
        usuario: 'Usuario xxx',
        comentario: 'operação realizada por tal coisa',
        anexos: [
          {
            nome: 'file.pdf',
            url: '#'
          }
        ]
      },
    ]
  };

}
